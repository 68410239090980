<template>
  <v-btn :loading="loading" color="blue" small depressed dark @click.stop="onClickPrint">
    <v-icon class="mr-1">mdi-printer</v-icon>
    {{ $t("global.print") }}
  </v-btn>
</template>

<script>
  export default {
    name: "print-leave-form-button",
    props: {
      leaveRequestId: {
        type: String,
        required: true,
        default: () => null
      }
    },
    data: () => ({
      loading: false
    }),
    methods: {
      onClickPrint() {
        this.loading = true;
        this.$api.leaveRequestService
          .form(this.leaveRequestId)
          .then(({ data }) => {
            if (!data.error) {
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `izin_formu_${this.$moment().format("DD-MM-YYYY-HH-mm-ss")}.pdf`);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
